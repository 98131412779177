
import { defineComponent } from 'vue'

import Center from '@/components/misc/Center.vue'
import ChangePassword from '@/components/profile/ChangePassword.vue';

export default defineComponent({
  name: 'Profile',
  components: {
    Center,
    ChangePassword
  },
  created() {
    if(!this.$store.state.auth.isLoggedIn) {
      this.$router.push('/login');
    }
  },
  data() {
    return {
      fullname: this.$store.getters.userFullname,
      email: this.$store.getters.userEmail, 
      changingPassword: false
    }
  }
})
