import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "d-flex justify-content-end" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Center = _resolveComponent("Center")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.error_message)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          modelValue: _ctx.error_message,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.error_message) = $event)),
          class: "alert-danger"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.success_message)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 1,
          modelValue: _ctx.success_message,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.success_message) = $event)),
          class: "alert-success",
          dismissible: false
        }, null, 8, ["modelValue"]))
      : (_openBlock(), _createElementBlock("form", {
          key: 2,
          onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
          class: "my-3"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.oldPassword) = $event)),
              class: "form-control",
              placeholder: "Old Password",
              disabled: _ctx.loading,
              required: ""
            }, null, 8, _hoisted_2), [
              [_vModelText, _ctx.oldPassword]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
              class: "form-control",
              placeholder: "New password",
              ref: "password",
              onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handlePasswordInput && _ctx.handlePasswordInput(...args))),
              disabled: _ctx.loading,
              required: ""
            }, null, 40, _hoisted_4), [
              [_vModelText, _ctx.password]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.passwordConfirmation) = $event)),
              class: "form-control",
              placeholder: "Confirm the new password",
              ref: "passwordConfirmation",
              onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handlePasswordInput && _ctx.handlePasswordInput(...args))),
              disabled: _ctx.loading,
              required: ""
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.passwordConfirmation]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_Center, {
                  key: 0,
                  class: "mx-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Spinner, {
                      size: "2rem",
                      thickness: "0.75rem"
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary me-3",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close'))),
              disabled: _ctx.loading
            }, "Cancel", 8, _hoisted_8),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary",
              disabled: _ctx.loading
            }, "Change password", 8, _hoisted_9)
          ])
        ], 32))
  ], 64))
}